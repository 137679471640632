<template>
  <add-role-form
      :abilities="abilities"
      :is-loading="isLoading"
      :repository="repository"
      @refresh-data="$router.replace({name: 'associated-roles'})"
  />
</template>

<script>
import AddRoleForm from '@/components/roles/form.vue'
import RepositoryFactory from "@/repositories/RepositoryFactory";

export default {
  components: {
    AddRoleForm,
  },
  data() {
    return {
      abilities: {},
      isLoading: false,
      repository: RepositoryFactory.create('associatedRoleRepo'),
    }
  },
  async created() {
    this.isLoading = true
    await this.getUtilsPermissions()
    this.isLoading = false
  },
  methods: {
    async getUtilsPermissions() {
      await this.utilsAppRepository().getPermissions()
          .then(res => {
            this.abilities = res.data.data.associated
          })
    },
  }
}
</script>
